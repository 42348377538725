import React, { useEffect } from "react";
// eslint-disable-next-line import/no-unresolved
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function BigMap({ listings, setActive }) {
  const MAP_BOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

  useEffect(() => {
    mapboxgl.accessToken = MAP_BOX_TOKEN;

    const map = new mapboxgl.Map({
      container: "map",
      center: [-120.6596, 35.2828],
      zoom: 0,
      minZoom: 12,
      maxZoom: 300,
      style: "mapbox://styles/mapbox/streets-v11",
      transformRequest: (url, resourceType) => {
        if (resourceType === "Source" && url.startsWith("http://myHost")) {
          return {
            headers: { "my-custom-header": true },
            credentials: "include",
          };
        }
        return null;
      },
    });

    if (listings)
      listings.forEach((listing) => {
        if (listing.rand_latitude && listing.rand_longitude) {
          const popup = new mapboxgl.Popup({
            offset: 30,
            closeButton: false,
          }).setText(listing.name);
          const marker = new mapboxgl.Marker({ color: "#ff6708" })
            .setLngLat([listing.rand_longitude, listing.rand_latitude])
            .setPopup(popup)
            .addTo(map);
          marker.getElement().style.cursor = "pointer";
          marker.getElement().addEventListener("mouseenter", () => {
            popup.addTo(map);
          });
          marker.getElement().addEventListener("mouseleave", () => {
            popup.remove();
          });
          marker.getElement().addEventListener("click", (e) => {
            setActive(listing.listing_id);
            e.stopPropagation();
          });
        }
      });

    return () => {
      map.remove();
    };
  }, [listings]);

  return <div id="map" className="big-map-view" />;
}

export default BigMap;
